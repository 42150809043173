// ** JWT Service Import
import ApiService from './apiService'

// ** Export Service as useJwt
export default function useApi(apiOverrideConfig) {
  const api = new ApiService(apiOverrideConfig)

  return {
    api
  }
}
