import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import apiDefaultConfig from '@src/@core/auth/api/apiDefaultConfig' 
import useApi from '@src/auth/api/useApi'
import ApiService from '@src/@core/auth/api/apiService'

const api = new ApiService({})

export const apiRTK = createApi({

    reducerPath: 'devicesApi',

    tagTypes: ['billdata', 'devicedata', 'consumo', 'devices', 'energy'],

    baseQuery: fetchBaseQuery({
        baseUrl: useApi.apiConfig.baseURL,
        prepareHeaders: (headers, { getState }) => {
            const accessToken = getState().auth?.userData?.accessToken
            headers.set('Content-Type', 'application/json')
            if (accessToken) {
                headers.set('Authorization', `${useApi.apiConfig.tokenType} ${accessToken}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        getDevices: builder.query({
            query: () => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: "getDevices",
                    sessionid: useApi.getSessionID()
                },
                validateStatus: (response, result) => {
                    if (response.status === 401 && result.error === 'invalid_token') {
                        api.clearStoredData()
                        return false
                    }
                    return true
                }
            }),
            providesTags: ['devices']
        }),
        updateClient: builder.mutation({
            query: (token) => ({
                method: 'POST',
                body: {
                    _operation: "clientOperations",
                    _method: "update",
                    sessionid: useApi.getSessionID(),
                    data: {
                        token_webapp_fcm: token
                    }
                }
            })
        }),
        getDevice: builder.query({
            transformResponse: (response) => response.result,
            query: (id) => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: "getDevice",
                    productid: id,
                    sessionid: useApi.getSessionID()
                }
            }),
            providesTags: ['devicedata']
        }),
        getConsumoEnPeriodo: builder.query({
            transformResponse: (response) => response.result,
            query: (productidData) => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: "getConsumoEnPeriodo",
                    productid: productidData,
                    sessionid: useApi.getSessionID()
                }
            }),
            providesTags: ['consumo']
        }),
        getBillingByMonthQty: builder.query({
            transformResponse: (response) => response.result,
            query: (data) => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: "getBillingByMonthQty",
                    productid: data.id,
                    sessionid: useApi.getSessionID()
                }
            }),
            providesTags: ['billdata']
        }),
        getEnergyByHours: builder.query({
            transformResponse: (response) => response.result,
            query: (id) => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: "getEnergyByHours",
                    productid: id,
                    sessionid: useApi.getSessionID()
                }
            }),
            providesTags: ['energy']
        }),       
        getHeatMapSemanal: builder.query({
            transformResponse: (response) => response.result,
            query: (id) => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: "getHeatMapSemanal",
                    productid: id,
                    sessionid: useApi.getSessionID()
                }
            })
        }),
        getPotenciaInstantanea: builder.query({
            transformResponse: (response) => response,
            query: (id) => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: "getPotenciaInstantanea",
                    productid: id,
                    sessionid: useApi.getSessionID()
                }
            })
        }),
        updateProductBills: builder.mutation({
            query: (params) => ({
                method: 'POST',
                body: {
                    _operation: "updateOperations",
                    _method: "updateProductBills",
                    sessionid: useApi.getSessionID(),
                    productid: params.id,
                    fechainicio: params.inicio,
                    presupuesto: params.presup
                }
            }),
            invalidatesTags: ['billdata', 'devicedata', 'energy', 'consumo']
        }),
        updateProductData: builder.mutation({
            query: (params) => ({
                method: 'POST',
                body: {
                    _operation: "updateOperations",
                    _method: "updateProduct",
                    sessionid: useApi.getSessionID(),
                    productid: params.productid,
                    data: params.data
                }
            }),
            invalidatesTags: ['billdata', 'devicedata', 'energy', 'consumo']
        }),
        getNotificationsProduct: builder.query({
            transformResponse: (response) => response.result.rows,
            query: (productidData) => ({
                method: 'POST',
                body: {
                    _operation: "clientOperations",
                    _method: "notificationsProduct",
                    productid: productidData,
                    sessionid: useApi.getSessionID()
                }
            })
        }),
        updateNotificationApi: builder.mutation({
            query: (dataNotification) => ({
                method: 'POST',
                body: {
                    _operation: "clientOperations",
                    _method: "updateNotificationState",
                    productid: dataNotification.productIds,
                    notificaciones: dataNotification.notificacionesCheck,
                    sessionid: useApi.getSessionID()
                }
            })
        }),
        getReport: builder.query({
            transformResponse: (response) => response,
            query: (bodyData) => ({
                method: 'POST',
                body: {
                    _operation: "customOperations",
                    _method: bodyData.method,
                    productid: bodyData.productid,
                    start: bodyData.start,
                    end: bodyData.end,
                    tipo: bodyData.tipo,
                    sessionid: useApi.getSessionID()
                }
            })
        })
    })
})

export const {
    useGetDevicesQuery,
    useLazyGetDevicesQuery,
    useGetDeviceQuery,
    useLazyGetDeviceQuery,
    useUpdateClientMutation,
    useGetBillingByMonthQtyQuery,
    useUpdateProductBillsMutation,
    useUpdateProductDataMutation,
    useGetConsumoEnPeriodoQuery,
    useGetEnergyByHoursQuery,
    useGetHeatMapSemanalQuery,
    useGetPotenciaInstantaneaQuery,
    useGetNotificationsProductQuery,
    useUpdateNotificationApiMutation,
    useLazyGetNotificationsProductQuery,
    useLazyGetReportQuery
} = apiRTK


export const apiLoginRTK = createApi({
    reducerPath: 'loginApi',

    baseQuery: fetchBaseQuery({
        baseUrl: apiDefaultConfig.baseURL,
        prepareHeaders: (headers) => {
            const accessToken = btoa(`${apiDefaultConfig.username}:${apiDefaultConfig.password}`)
            headers.set('Content-Type', 'application/json')
            if (accessToken) {
                headers.set('Authorization', `Basic ${accessToken}`)
            }
            return headers
        }
    }),
    endpoints: (builder) => ({
        login: builder.query({
            query: (data) => ({
                method: 'POST',
                body: {
                    _operation:"login",
                    grant_type: "client_credentials",
                    username: data.loginEmail, 
                    password: data.password
                }
            }),
            invalidatesTags: ['devices']
        })
    })
})

export const {
    useLazyLoginQuery
} = apiLoginRTK
