// ** Redux Imports
import { createSlice } from '@reduxjs/toolkit'

// ** UseJWT import to get config
// import useApi from '@src/auth/api/useApi'

// const config = useApi.apiConfig

const initialDevices = () => {
  const item = window.localStorage.getItem('devices')
  return item ? JSON.parse(item) : []
}
const initialDeviceselected = () => {
  const item = window.localStorage.getItem('deviceselected')
  return item ? JSON.parse(item) : []
}

export const devicesSlice = createSlice({
  name: 'devices',
  initialState: {
    devices: initialDevices(),
    deviceselected: initialDeviceselected()
  },
  reducers: {
    handleDevices: (state, action) => {
        state.devices = action.payload
        localStorage.setItem('devices', JSON.stringify(action.payload))
    },
    setDeviceselected: (state, action) => {
        state.deviceselected = action.payload
        localStorage.setItem('deviceselected', JSON.stringify(action.payload))
    },
    cleanDevices: (state) => {
        state.devices = null
        localStorage.removeItem('devices')
        state.deviceselected = null
        localStorage.removeItem('deviceselected')

    }
  }
})

export const { handleDevices, setDeviceselected, cleanDevices } = devicesSlice.actions

export default devicesSlice.reducer
