// ** Redux Imports
import rootReducer from './rootReducer'
import { configureStore } from '@reduxjs/toolkit'
import {apiRTK, apiLoginRTK} from '../@core/auth/api/apiRTK'
import { setupListeners } from '@reduxjs/toolkit/dist/query'

const store = configureStore({
  reducer: {...rootReducer, 
    [apiRTK.reducerPath]: apiRTK.reducer, 
    [apiLoginRTK.reducerPath]: apiLoginRTK.reducer
  },
  middleware: getDefaultMiddleware => {
    return getDefaultMiddleware({
      serializableCheck: false
    }).concat(apiRTK.middleware).concat(apiLoginRTK.middleware)
  }
})

setupListeners(store.dispatch)

export { store }
