import axios from 'axios'
import apiDefaultConfig from './apiDefaultConfig'

const axiosClient = axios.create({
  baseURL: apiDefaultConfig.baseURL,
  headers: {
    'Content-Type': 'application/json'
  }
})

export default class apiService {
  // ** apiConfig <= Will be used by this service
  apiConfig = { ...apiDefaultConfig }

  // ** For Refreshing Token
  isAlreadyFetchingAccessToken = false

  // ** For Refreshing Token
  subscribers = []

  constructor(apiOverrideConfig) {
    this.apiConfig = { ...this.apiConfig, ...apiOverrideConfig }
    // ** Request Interceptor
    axiosClient.interceptors.request.use(
      config => {
        // ** Get token from localStorage
        const accessToken = this.getToken()
        const sessionid = this.getSessionID()
        // ** If token is present add it to request's Authorization Header
        if (accessToken && config.data.grant_type !== 'refresh_token') {
          // ** eslint-disable-next-line no-param-reassign
          config.headers.Authorization = `${this.apiConfig.tokenType} ${accessToken}`
        }
        if (sessionid) {
          // ** eslint-disable-next-line no-param-reassign
          let json = config.data
          if (typeof json === 'string') {
            json = JSON.parse(json)
          }
          json.sessionid = `${sessionid}`
          config.data = json
        }
        return config
      },
      error => Promise.reject(error)
    )

    // ** Add request/response interceptor
    axiosClient.interceptors.response.use(
      response => {
        if (response && response.data.success === false && response.data.error.code === 1) {
          this.clearStoredData()
        }
        // const originalResponse = response
        return response
      },
      error => {
        const { config, response: { status, statusText, data } } = error
        const originalRequest = config
          
        if (status === 400 && data.error === "invalid_grant") {
          this.clearStoredData()
        }

        if (status === 401 && this.getRefreshToken() !== 'undefined') {

          if (!this.isAlreadyFetchingAccessToken) {
            this.isAlreadyFetchingAccessToken = true
            this.refreshToken().then(r => {
              this.isAlreadyFetchingAccessToken = false

              // ** Update accessToken in localStorage
              this.setToken(r.data.access_token)
              // this.setRefreshToken(r.data.refresh_token)

              this.onAccessTokenFetched(r.data.access_token)
            })
          }
          const retryOriginalRequest = new Promise(resolve => {
            this.addSubscriber(accessToken => {
              // ** Make sure to assign accessToken according to your response.
              // ** Check: https://pixinvent.ticksy.com/ticket/2413870
              // ** Change Authorization header
              originalRequest.headers.Authorization = `${this.apiConfig.tokenType} ${accessToken}`
              resolve(axiosClient(originalRequest))
            })
          })
          return retryOriginalRequest
        } else if (statusText === 'Unauthorized' && status === 401) {
          this.clearStoredData()
        } else if (status === 404 && statusText === 'Access denied. Login required.') {
          this.clearStoredData()
        }
        return Promise.reject(error)
      }
    )
  }

  clearStoredData() {
    localStorage.removeItem('devices')
    localStorage.removeItem('deviceselected')
    localStorage.removeItem('refresh_token')
    localStorage.removeItem('sessionid')
    localStorage.removeItem('access_token')
    localStorage.removeItem('userData')
    window.location.href = '/login'
  }

  onAccessTokenFetched(accessToken) {
    this.subscribers = this.subscribers.filter(callback => callback(accessToken))
  }

  addSubscriber(callback) {
    this.subscribers.push(callback)
  }

  getToken() {
    return localStorage.getItem(this.apiConfig.storageTokenKeyName)
  }

  getSessionID() {
    return localStorage.getItem(this.apiConfig.storageSessionKeyName)
  }

  getRefreshToken() {
    return localStorage.getItem(this.apiConfig.storageRefreshTokenKeyName)
  }

  getLocalItem(item) {
    return JSON.parse(localStorage.getItem(item))
  }

  setToken(value) {
    localStorage.setItem(this.apiConfig.storageTokenKeyName, value)
  }

  setRefreshToken(value) {
    localStorage.setItem(this.apiConfig.storageRefreshTokenKeyName, value)
  }

  login(...args) {
    return axiosClient.post(this.apiConfig.loginEndpoint, ...args, {
      auth: {
        username: apiDefaultConfig.username,
        password: apiDefaultConfig.password
      }
    })
  }
  password(...args) {
    return axiosClient.post(this.apiConfig.loginEndpoint, ...args)
  } 

  register(...args) {
    return axiosClient.post(this.apiConfig.registerEndpoint, ...args)
  }

  refreshToken() {
    return axiosClient.post(this.apiConfig.refreshEndpoint, {
      _operation: 'refresh_token',
      grant_type: 'refresh_token',
      refresh_token: this.getRefreshToken()
    },
    {
      auth: {
        username: apiDefaultConfig.username,
        password: apiDefaultConfig.password
      }
    })
  }

  /**
   * obtiene de Api cualquier consulta
   */
  async getFromApi(...args) {
    return await axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }
/**
   * obtiene todos los dispositivos
   */
 getDevices(...args) {
  return axiosClient.post(this.apiConfig.baseURL, ...args)
  .then((response) => {
    if (response) {
      return response.data
    }
    return false
  })
  .catch((err) => console.log(err))
}

  /**
   * Obtiene info de un dispositivos especifico
   */
  getDevice(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }

  /**
   * Obtiene consumo en el periodo en curso
   */
  getConsumoEnPeriodo(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }

  /**
   * Obtiene ptencia instantanea ultimas 6 horas
   */
   getPotenciaInstantanea(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }

  /**
   * Obtiene HeatMap de consumo semana vs horario en el periodo en curso
   */
   getHeatMapSemanal(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }

  /**
   * Obtiene la facturacion por defecto de los ultimos 12 meses
   * @params meses puede cambiar 
   */
   getBillingByMonthQty(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }

  /**
   * obtiene notificaciones
   */
  getNotifications(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }
  /**
   * obtiene cantidad de notificaciones no leidas
   */
  getNotificationsCount(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }
  /**
   * actualiza notificaciones que se deben vincular con producto
   */
  updateNotificationState(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }

  /**
   * obtiene energia horaria 24hs
   */
  getEnergyByHours(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }


  /**
   * actualiza en producto y ultimas facturas valores de ultima_lectura y presupuesto_periodo
   */
   updateProductBills(...args) {
    return axiosClient.post(this.apiConfig.baseURL, ...args)
    .then((response) => {
      if (response) {
        return response.data
      }
      return false
    })
    .catch((err) => console.log(err))
  }
}