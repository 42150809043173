// ** Logo
import logo from '@src/assets/images/logo/logo.png'

const SpinnerComponent = () => {
  return (
    <div className='fallback-spinner app-loader'>
      <div className='loading'>
        {/* <div className='effect-1 effects'>
          <img src={logo} alt='logo' width='48' />
        </div> */}
        <div className='effect-2 effects'>
          <img className='fallback-logo' src={logo} alt='logo' width='48' />
        </div>
        {/* <div className='effect-3 effects'>
          <img className='fallback-logo' src={logo} alt='logo' width='48' />
        </div> */}
      </div>
    </div>
  )
}

export default SpinnerComponent
